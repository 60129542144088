import React, { useState, useEffect } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Typography,
    Avatar,
  } from "@mui/material";
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  fetchCommentsForTicket,
} from "app/Api Calls/Comments";
import { getSpecificUser } from "app/Api Calls/GetSpecificUser";

function TicketComments({ id, ticketDescription}) {
    const [areCommentsFetched, setAreCommentsFetched] = useState(false);
    const [comments, setComments] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleAccordionChange = async (event, isExpanded) => {
        setExpanded(isExpanded);
        if (isExpanded && !areCommentsFetched) {
            setIsLoading(true); // 
            await fetchCommentsData();
            setIsLoading(false); 
        }
    };

    const fetchCommentsData = async () => {
        const fetchedComments = await fetchCommentsForTicket(id);

        const userIdsToFetch = new Set(fetchedComments.map(comment => comment.whoIsCreated).filter(Boolean));

        // Fetch user data for each unique ID
        const fetchedUsers = {};
        await Promise.all(Array.from(userIdsToFetch).map(async (userId) => {
            fetchedUsers[userId] = await getSpecificUser(userId);
        }));

        // Combine comments with user data
        const fetchedCommentsWithUser = fetchedComments.map(comment => {
            return { ...comment, user: fetchedUsers[comment.whoIsCreated] };
        });

        setComments(fetchedCommentsWithUser);
        setAreCommentsFetched(true);
    };


    return (
        <div className="mb-2">
            <Accordion style={{ backgroundColor: '#C2E0FF' }} onChange={handleAccordionChange}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6">Ερώτηση Και Απάντηση Ticket</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: '#C2E0FF', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {isLoading ? (
                        <CircularProgress />
                    ) : (
                        <div style={{ width: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Ερώτηση Ticket
                            </Typography>
                            <Typography className="p-4" sx={{backgroundColor: "white", wordWrap: "break-word"}} variant="body1" dangerouslySetInnerHTML={{ __html: ticketDescription }} />
                            
                            <Typography variant="h6" gutterBottom style={{marginTop: '20px'}}>
                                Απάντηση Ticket
                            </Typography>
                            <div className="w-full flex flex-col h-full p-4" style={{ maxHeight: "500px", overflowY: "auto", backgroundColor: '#FFFFFF' }}>
                                <div className="comments-section overflow-auto">
                                    {comments && comments.length > 0 ? (
                                        comments.map((comment, index) => (
                                            <div key={index} className="flex items-start gap-2 mb-2">
                                                <Avatar />
                                                <div className="flex flex-col">
                                                    <p
                                                        dangerouslySetInnerHTML={{ __html: comment.note }}
                                                    ></p>
                                                    <div className="text-sm text-gray-400">
                                                        <p>
                                                            {`${comment.user?.firstName || ""} ${comment.user?.lastName || ""}`.trim() || "Άγνωστος"}
                                                        </p>
                                                        <p>
                                                            {new Date(comment.createdAt).toLocaleDateString("el-GR", {
                                                                day: "2-digit",
                                                                month: "2-digit",
                                                                year: "numeric",
                                                            })}{" "}
                                                            {new Date(comment.createdAt).toLocaleTimeString("el-GR", {
                                                                hour: "2-digit",
                                                                minute: "2-digit",
                                                            })}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <h4 >Δεν υπάρχει απάντηση</h4>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </AccordionDetails>
            </Accordion>
        </div>
    );
    
  
}
export default TicketComments;
