import {useEffect, useRef, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {
    resetSearchParameter,
    resetUserForm,
    resetUserFormExceptErotisi,
    setBeneficiaryHasOpenTickets,
    setHasBeneficiaryFetched,
    setOriginalBeneficiaryData,
    setShouldCreateBeneficiary,
    updateBeneficiaryData,
} from "store/userInfoSlice";
import {CircularProgress, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {
    clearBeneficiaryLastSearchParams, clearSpecificLastSearchParam,
    setAllBeneficiaryLastSearchParams,
    setBeneficiaryLastSearchParams, setBeneficiaryTickets,
    setFetchedBeneficiaries, setIsLoadingBeneficiary,
    setLastSearchParams,
} from "store/beneficiaryFetchSlice";
import searchTickets from "app/Api Calls/GetTicketsForAllRoles";
import searchBeneficiary from "app/Api Calls/SearchBeneficiary";
import {showMessage} from "store/messageSlice";
import {getTicketsPerStatus} from "app/Api Calls/FetchBeneficiaryTickets";
import {setHistoryModal} from "store/ticketCreateSlice";
import {setFollowupTicketId} from "store/historyTicketSlice";

function FetchBeneficiary({accordionRef}) {
    const afm = useSelector((state) => state.userInfoSlice.afm);
    const amka = useSelector((state) => state.userInfoSlice.amka);
    const isAfmValid = useSelector((state) => state.userInfoSlice.isAfmValid);
    const isAmkaValid = useSelector((state) => state.userInfoSlice.isAmkaValid);
    const isPhoneValid = useSelector((state) => state.userInfoSlice.isPhoneValid);
    const isMobileValid = useSelector(
        (state) => state.userInfoSlice.isMobileValid
    );
    const dispatch = useDispatch();
    const [showAlert, setShowAlert] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [beneficiaryData, setBeneficiaryData] = useState(null);
    const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
    const isLoading = useSelector((state) => state.beneficiaryFetchSlice.isLoadingBeneficiary)
    const phone = useSelector((state) => state.userInfoSlice.phone);
    const mobile = useSelector((state) => state.userInfoSlice.mobile);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const lastSearchParams = useSelector(
        (state) => state.beneficiaryFetchSlice.lastBeneficiarySearchParams
    );
    const fetchedBeneficiaries = useSelector(
        (state) => state.beneficiaryFetchSlice.fetchedBeneficiaries
    );
    const shouldCreateBeneficiary = useSelector(
        (state) => state.userInfoSlice.shouldCreateBeneficiary
    );
    const currentBeneficiary = useSelector((state) => state.userInfoSlice.currentBeneficiary);
    const followUpTicketId = useSelector((state) => state.historyTicketSlice.followupTicketId);

    useEffect(() => {
        const validInputs = {
            afm: isAfmValid ? afm : null,
            amka: isAmkaValid ? amka : null,
            phone: isPhoneValid ? phone : null,
            mobile: isMobileValid ? mobile : null,
        };

        const reassessCreateOrUpdate = () => {
            // Filter validInputs to exclude phone and mobile for all checks
            const filteredValidInputs = Object.entries(validInputs).reduce((acc, [key, value]) => {
                if (key !== "phone" && key !== "mobile") {
                    acc[key] = value;
                }
                return acc;
            }, {});

            // Check if any valid input (excluding phone and mobile) matches exactly one fetched beneficiary
            const matchesFetchedBeneficiary = fetchedBeneficiaries.some(fetchedBeneficiary =>
                Object.entries(filteredValidInputs).some(([key, validInputValue]) =>
                    validInputValue && fetchedBeneficiary[key] === validInputValue
                )
            );

            // Determine if a modification of inputs (excluding phone and mobile) does not conflict with existing fetched beneficiaries
            const modificationWithoutConflict = Object.entries(filteredValidInputs).some(([key, validInputValue]) => {
                // Attempt to find a potential update beneficiary based on at least one matching input (excluding phone and mobile)
                const potentialUpdateBeneficiary = fetchedBeneficiaries.find(fetchedBeneficiary =>
                    Object.entries(filteredValidInputs).some(([innerKey, innerValue]) =>
                        key !== innerKey && innerValue && fetchedBeneficiary[innerKey] === innerValue
                    )
                );
                return validInputValue && potentialUpdateBeneficiary;
            });

            // Determine if it's an update scenario based on the checks above
            const isUpdateScenario = matchesFetchedBeneficiary || modificationWithoutConflict;

            // Adjust the flags based on whether it's an update scenario
            dispatch(setShouldCreateBeneficiary(!isUpdateScenario));
            dispatch(setHasBeneficiaryFetched(isUpdateScenario));
        };


        const triggerSearchAndUpdateLastSearch = (key, value) => {
           if (validInputs[key] && value !== lastSearchParams[key]) {
                fetchData(key, value);
                dispatch(setBeneficiaryLastSearchParams({[key]: value}));
            }
        };

        const clearIfModifiedAndReassess = (key, value) => {
            if (value !== '' && value !== lastSearchParams[key]) {
                dispatch(clearSpecificLastSearchParam(key));
                reassessCreateOrUpdate();
            }
        };

        // Iterate over valid inputs to either trigger search or reassess the creation/update status
        Object.entries(validInputs).forEach(([key, value]) => {
            if (value) {
                triggerSearchAndUpdateLastSearch(key, value);
            } else {
                clearIfModifiedAndReassess(key, value);
            }
        });

        // Call reassessCreateOrUpdate initially to set correct flags based on current inputs
        reassessCreateOrUpdate();
    }, [afm, amka, phone, mobile, isAfmValid, isAmkaValid, isPhoneValid, isMobileValid, dispatch, fetchedBeneficiaries]);


    const fetchData = async (key, value) => {
        dispatch(setIsLoadingBeneficiary(true));
        try {
            const response = await searchBeneficiary({[key]: value}, page, size);
            if (response && response.totalElements > 0) {
                dispatch(setBeneficiaryLastSearchParams({[key]: value}));
                dispatch(setFetchedBeneficiaries(response.elements));
                if (response.elements.length >= 1) {
                    setSelectedBeneficiary(response.elements[0]);
                }
                setBeneficiaryData(response.elements);
                setShowDialog(true);
            }
        } catch (error) {
            console.error("Error fetching beneficiary:", error);
        } finally {
            dispatch(setIsLoadingBeneficiary(false));
        }
    };

    // Function for confirm button click
    const handleConfirm = async () => {
        try {
            if (selectedBeneficiary) {
                dispatch(resetUserFormExceptErotisi());
                dispatch(updateBeneficiaryData(selectedBeneficiary));
                dispatch(setOriginalBeneficiaryData(selectedBeneficiary));
                dispatch(setShouldCreateBeneficiary(false));
                if (
                    (followUpTicketId?.id !== null && followUpTicketId?.uuid !== null )    ||
                    Object.keys(followUpTicketId).length === 0
                ) {
                    dispatch(setFollowupTicketId({
                        id: null,
                        uuid: null,
                    }));
                }
                setShowAlert(true);
                dispatch(setHasBeneficiaryFetched(true));
                const {afm, amka, phone, mobile} = selectedBeneficiary;
                const newSearchParams = {
                    afm: afm || null,
                    amka: amka || null,
                    phone: phone || null,
                    mobile: mobile || null,
                };
                dispatch(setAllBeneficiaryLastSearchParams(newSearchParams));
            }
            setShowDialog(false);
            dispatch(
                showMessage({
                    message: `Τα στοιχεία του χρήστη έχουν συμπληρωθεί`,
                    autoHideDuration: 2000,
                    anchorOrigin: {
                        vertical: "top",
                        horizontal: "center",
                    },
                    variant: "success",
                })
            );
            // Make the request to searchTickets
            const beneficiaryId = selectedBeneficiary?.id;
            if (beneficiaryId) {
                const data = await getTicketsPerStatus(beneficiaryId);
                const beneficiaryHasOpenTickets = data.some(ticket => ticket.status4 > 0 || ticket.status8 > 0);
                dispatch(setBeneficiaryHasOpenTickets(beneficiaryHasOpenTickets));
                dispatch(setBeneficiaryTickets({ beneficiaryId, tickets: data }));

            if (beneficiaryHasOpenTickets) {
                dispatch(setHistoryModal(true));
            }
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function for closing dialog
    const handleCloseDialog = () => {
        // Function to find if any field matches between two beneficiaries
        const isAnyFieldMatching = (beneficiary1, beneficiary2) => {
            const fieldsToCompare = [...new Set([...Object.keys(beneficiary1), ...Object.keys(beneficiary2)])];

            return fieldsToCompare.some(field => beneficiary1[field] === beneficiary2[field]);
        };


        // Iterate over fetched beneficiaries to check if any field matches with currentBeneficiary
        const matchingFields = new Set();

        fetchedBeneficiaries.forEach(fetchedBeneficiary => {
            if (isAnyFieldMatching(currentBeneficiary, fetchedBeneficiary)) {
                // If any field matches, add this field to the matchingFields set
                Object.keys(currentBeneficiary).forEach(key => {
                    if (currentBeneficiary[key] === fetchedBeneficiary[key]) {
                        matchingFields.add(key);
                    }
                });
            }
        });

        // Clear matching search parameters based on the identified matching fields
        matchingFields.forEach(field => {
            dispatch(resetSearchParameter(field));
            dispatch(clearSpecificLastSearchParam(field));
            dispatch(setOriginalBeneficiaryData([]));
        });


        dispatch(setShouldCreateBeneficiary(true));
        dispatch(setHasBeneficiaryFetched(false));
        setBeneficiaryData(null);
        setSelectedBeneficiary(null);
        setShowDialog(false);
    };


    return (
        <>

            <Dialog
                open={showDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {!isLoading && (
                    <DialogTitle id="alert-dialog-title">
                        Ο Χρήστης έχει ξανακαλέσει και τα στοιχεία του βρίσκονται στην Bάση Δεδομένων. Να συμπληρωθούν
                        τα στοιχεία του;
                    </DialogTitle>
                )}
                <DialogContent>
                    {isLoading ? (
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress/>
                        </div>
                    ) : (
                        <RadioGroup
                            value={selectedBeneficiary ? selectedBeneficiary.id.toString() : ""}
                            onChange={(event) => {
                                const beneficiary = beneficiaryData.find(
                                    (b) => b.id.toString() === event.target.value
                                );
                                setSelectedBeneficiary(beneficiary);
                            }}
                        >
                            {beneficiaryData &&
                                beneficiaryData.map((data, index) => (
                                    <div key={index}>
                                        <FormControlLabel
                                            value={data.id.toString()}
                                            control={<Radio/>}
                                            label={
                                                <>
                                                    Όνομα: {data.firstName}
                                                    <br/>
                                                    Επώνυμο: {data.lastName}
                                                    <br/>
                                                    Email: {data.email}
                                                    <br/>
                                                    Τηλέφωνο: {data.phone}
                                                    <br/>
                                                    ΑΦΜ: {data.afm}
                                                    <br/>
                                                    AMKA: {data.amka}
                                                    <br/>
                                                    Διεύθυνση: {data.address}
                                                    <br/>
                                                    Κινητό: {data.mobile}
                                                </>
                                            }
                                        />
                                        <hr/>
                                    </div>
                                ))}
                        </RadioGroup>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" disabled={isLoading}>
                        Όχι
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus disabled={isLoading}>
                        Ναι
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default FetchBeneficiary;
